/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import ImageUploading from 'react-images-uploading'

import { useUploadImage } from '../../hooks/useUploadImage'
import BoxDragAndDrop from './BoxDragAndDrop'
import './DragAndDrop.css'
import ImageSelected from './ImageSelected'

const DragAndDrop = ({ value = {}, handleUploadFile = () => {} }) => {
	const { handleChange, images, onUpload, ...rest } = useUploadImage(value)
	useEffect(() => {
		images.length && handleUploadFile({ onUpload, images })
	}, [images])

	return (
		<div className="img-uploaded ratio-75">
			<ImageUploading className="img" multiple={false} value={images} onChange={handleChange} maxNumber={1}>
				{({ imageList, onImageUpload, dragProps, isDragging, onImageRemove, onImageUpdate }) => {
					const haveImg = imageList[0] || value.urlImagen || value.dataURL
					return (
						<>
							{haveImg && false ? (
								<ImageSelected
									{...{ onImageRemove, onImageUpdate, onUpload, ...rest }}
									img={imageList[0]}
									value={value.urlImagen || value.dataURL}
								/>
							) : (
								<BoxDragAndDrop {...{ onImageUpload, dragProps, isDragging, value }} img={imageList[0]} value={value.urlImagen || value.dataURL} />
							)}
						</>
					)
				}}
			</ImageUploading>
		</div>
	)
}

export default DragAndDrop
