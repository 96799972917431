import JSON5 from "json5";
import { defaultDataProvider } from "../../providers/data";

const openaiProvider = defaultDataProvider("https://api.openai.com/v1/chat/");
const googleProvider = defaultDataProvider("https://generativelanguage.googleapis.com/v1beta/models/");
const dataProvider = defaultDataProvider("https://www.ichef.uy:8443/ICHEF-WAR/");
const searchEngineProvider = defaultDataProvider(process.env.REACT_APP_SEARCH_ENGINE_API_PATH);

const testData = {};
const useGpt = true;

function operacionesReverseMap(idOperacion) {
	const operaciones = {
		1: "manual",
		2: "vaporera",
		3: "amasar",
		5: "cocinar",
		6: "pesar",
		8: "procesar",
		9: "subtitulo",
		12: "precalentar"
	};
	return operaciones[idOperacion];
}

function removeNullValues(obj) {
	for (const key in obj) {
		if (obj[key] === null) {
			delete obj[key];
		} else if (typeof obj[key] === "object") {
			if (Array.isArray(obj[key])) {
				obj[key] = obj[key].map(item => {
					if (typeof item === "object") {
						return removeNullValues(item);
					}
					return item;
				});
			} else {
				obj[key] = removeNullValues(obj[key]);
			}
		}
	}
	return obj;
}

function CompareStr(str1, str2) {
	return str1.toLowerCase() === str2.toLowerCase();
}

function IncludesStr(str1, str2) {
	return str1.toLowerCase().includes(str2.toLowerCase());
}

function FindMostSubstrings(words, concated, field) {
	let maxSubstringCount = 0;
	let entryWithMostSubstrings = concated[0];
	concated.forEach(entry => {
		let substringCount = 0;
		words.forEach(word => {
			if (word.length > 2 && IncludesStr(entry[field], word)) substringCount++;
			if (word === "pollo" && IncludesStr(entry[field], "gallina")) substringCount++;
		});
		if (substringCount > maxSubstringCount) {
			maxSubstringCount = substringCount;
			entryWithMostSubstrings = entry;
		}
	});
	return entryWithMostSubstrings;
}

// eslint-disable-next-line
function getIdInsumo(insumo, ingredientes) {
	if (!insumo) return 0;

	const defaultIngredients = {
		Aceite: "Aceite de girasol",
		Arroz: "Arroz blanco, crudo",
		Chocolate: "Chocolate con leche",
		Harina: "Harina de trigo",
		Huevo: "Huevo de gallina, entero, crudo",
		Huevos: "Huevo de gallina, entero, crudo",
		Leche: "Leche de vaca entera",
		Mantequilla: "Manteca, fresca",
		"Pimiento rojo": "Morrón",
		"Pimiento verde": "Morrón",
		"Queso rallado": "Queso parmesano",
		Queso: "Queso tipo colonia"
	};
	insumo = defaultIngredients[insumo] || insumo;

	const foundFullName = ingredientes.find(item => CompareStr(item.nombreInsumo, insumo));
	if (foundFullName) return foundFullName.idInsumo;

	const words = insumo.split(" ");
	const wordsSingular = words.map(word => {
		if (word.toLowerCase() === "ajo") return "ajos";
		if (word.toLowerCase() === "tomates") return "tomate";
		if (word.endsWith("es")) return word.slice(0, -2);
		if (word.endsWith("s")) return word.slice(0, -1);
		return word;
	});

	let sameIngredient = [];
	for (const word of wordsSingular) {
		if (word.length < 3) continue;
		const options = ingredientes.filter(i => {
			const descripcionCorta = i.descripcionCorta !== "Vacuno" ? i.descripcionCorta : "Carne";
			if (CompareStr(descripcionCorta, word)) return true;
			for (const alias of i.alias) if (CompareStr(alias.alias, word)) return true;
			return false;
		});
		sameIngredient = sameIngredient.concat(options);
	}
	if (sameIngredient.length > 0) {
		const bestMatch = FindMostSubstrings(wordsSingular, sameIngredient, "nombreInsumo");
		return bestMatch.idInsumo;
	}

	const aliasOptions = ingredientes.filter(i => {
		for (const alias of i.alias) if (CompareStr(alias.alias, insumo)) return true;
		return false;
	});
	if (aliasOptions.length > 0) return aliasOptions[0].idInsumo;
	return 0;
}

async function getIdInsumoSearch(nombreInsumo, insumos) {
	const foundFullName = insumos.find(item => CompareStr(item.nombreInsumo, nombreInsumo));
	if (foundFullName) return foundFullName.idInsumo;

	const { data, status } = await searchEngineProvider.create("ingredientes/search", {
		headers: {
			Authorization: `Bearer ${process.env.REACT_APP_SEARCH_ENGINE_PRIVATE_KEY}`
		},
		data: {
			"query": nombreInsumo,
			"precision": 3,
			"search_fields": {
				"nombre_ingrediente": {
					"weight": 14
				},
				"descripcion_corta": {
					"weight": 15
				},
				"alias": {
					"weight": 2
				}
			},
			"result_fields": {
				"nombre_ingrediente": {
					"raw": {}
				},
				"id_insumo": {
					"raw": {}
				}
			},
			"page": {
				"size": 1,
				"current": 1
			}
		}
	});
	if (status === 200) {
		if (data.results && data.results.length > 0) {
			const idInsumo = data.results[0].id_insumo.raw;
			return parseInt(idInsumo);
		}
	}
	return 0;
}

async function getRecipeInfo(idRecipe) {
	if (!idRecipe) return { texto: "", json: {} };
	const { data: recipe } = await dataProvider.getOne(`api/recetas/${idRecipe}`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json, text/plain, */*"
		}
	});
	const translate = Math.max(recipe?.traducciones?.findIndex(({ codLenguaje }) => codLenguaje === "es") || 0, 0)
	const tituloReceta = recipe.traducciones[translate].titulo;
	const ingredientes = recipe.traducciones[translate].ingredientes;
	const pasos = recipe.traducciones[translate].pasos;
	const preparacion = recipe.traducciones[translate].preparacion;
	const porciones = recipe.traducciones[translate].porciones;

	let textoReceta = tituloReceta + "\n";
	ingredientes.forEach(ingrediente => {
		textoReceta += ingrediente.descripcion + "\n";
	});
	preparacion.forEach(paso => {
		textoReceta += paso.titulo ? paso.titulo + "\n" : "";
		textoReceta += paso.parrafo + "\n";
	});

	let jsonRecipe = {
		titulo: tituloReceta,
		ingredientes: ingredientes.map(ingrediente => ({
			cantidad: ingrediente.cantidad,
			unidad: ingrediente.nombreUnidad,
			insumo: ingrediente.nombreInsumo,
			descripcion: ingrediente.descripcion
		})),
		pasos: pasos.map(paso => ({
			descripcion: paso.descripcion,
			cantidad: paso.cantidad,
			temperatura: paso.temperatura,
			velocidad: paso.nivelVelocidad,
			tiempo: paso.tiempo,
			direccion: paso.idModoVelocidad === 1 ? "Reversa" : paso.idModoVelocidad === 2 ? "Avance" : null,
			operacion: operacionesReverseMap(paso.idOperacion)
		}))
	};
	jsonRecipe = removeNullValues(jsonRecipe);
	return { texto: textoReceta, json: jsonRecipe, porciones };
}

async function QueryGPT(messages) {
	try {
		console.log(messages);
		const response = await openaiProvider.create("completions", {
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
			},
			data: {
				model: "gpt-3.5-turbo-0125",
				messages
			}
		});
		//console.log("response", response.data)
		const data = response.data.choices[0].message.content;
		return data;
	} catch (error) {
		console.log("ERROR QueryGPT:", error.message);
		return "";
	}
}

async function QueryGemini(query) {
	try {
		//console.log("query", query)
		const response = await googleProvider.create(`gemini-pro:generateContent?key=${process.env.REACT_APP_DEEPMIND_API_KEY}`, {
			data: {
				contents: [
					{
						parts: [
							{
								text: query
							}
						]
					}
				]
			}
		});
		//console.log("response", response.data)
		const data = response.data.candidates[0].content.parts[0].text;
		return data;
	} catch (error) {
		console.log("ERROR:", error.message);
		return "";
	}
}

function QueryAI(query) {
	if (useGpt) return QueryGPT(query);
	else return QueryGemini(query);
}

function convertRecipe(titulo, preparacion) {
	const userQuery = {
		role: "user",
		content: `
Convierte la siguiente receta para preparar en iChef, separando los pasos en varias operaciones a realizar en iChef.
${titulo}
${preparacion}
`
	};
	return userQuery;
}

function createRecipe(titulo, categoria, tipoPreparacion, ingredientes, observaciones, porciones) {
	const userQuery = {
		role: "user",
		content: `
Crea una receta para preparar en iChef.
${titulo ? `Título: ${titulo}` : ""}
${porciones > 0 ? `Para ${porciones} porciones.` : ""}
${ingredientes ? `Que incluya los sigientes ingredientes y los demás que sean necesarios: ${ingredientes}` : ""}
${observaciones ? `Con las siguientes características: ${observaciones}` : ""}
`
	};
	//${categoria ? `Categoría: ${categoria}` : ""}
	//${tipoPreparacion ? `Tipo de preparación: ${tipoPreparacion}` : ""}
	return userQuery;
}

function modifyRecipe(titulo, tituloOriginal, porciones, porcionesOriginal, ingredientes, observaciones) {
	const userQuery = {
		role: "user",
		content: `
${titulo !== tituloOriginal ? `Crea una receta de: ${titulo}` : "Modifica la receta anterior."}
${porciones > 0 && porciones !== porcionesOriginal ? `Para que en cambio sea para ${porciones} porciones.` : ""}
${ingredientes ? `Que además incluya los sigientes ingredientes: ${ingredientes}` : ""}
${observaciones ? `Y que cumpla con las siguientes características: ${observaciones}` : ""}
`
	};
	return userQuery;
}

async function generateRecipeJSON(userQuery, exampleIdRecipes, insumos, convertir) {
	const exampleJson = {
		titulo: "Título de la receta",
		ingredientes: [
			{
				cantidad: 0,
				unidad: "gramos, ml, cucharada, cucharadita o taza",
				insumo: "Nombre del ingrediente",
				descripcion: "Ejemplo: 250 gramos de harina de trigo"
			}
		],
		pasos: [
			{
				operacion: "manual",
				descripcion: "Descripción del paso"
			},
			{
				operacion: "pesar",
				cantidad: 0,
				descripcion: "Ejemplo: Agregar 250 gramos de harina al vaso de iChef"
			},
			{
				operacion: "amasar",
				tiempo: 0
			},
			{
				operacion: "subtitulo",
				descripcion: "Subtítulo"
			},
			{
				operacion: "vaporera",
				direccion: "reversa",
				velocidad: 0,
				tiempo: 0
			},
			{
				operacion: "procesar",
				direccion: "reversa",
				velocidad: 0,
				tiempo: 0
			},
			{
				operacion: "cocinar",
				temperatura: 0,
				direccion: "reversa",
				velocidad: 0,
				tiempo: 0
			},
			{
				operacion: "horno",
				descripcion: "Ejemplo: Llevar al horno a 180 grados durante 30 minutos"
			}
		]
	};

	const systemQuery = {
		role: "system",
		content: `
Tu objetivo es crear una receta para preparar en iChef, un robot de cocina similar a Thermomix.
Para esto deverás devolver unicamente un objeto JSON con la siguiente estructura:
${JSON5.stringify(exampleJson)}

Agregar la operación "pesar" antes de agregar cada ingrediente con el campo "cantidad" en gramos.
El campo "tiempo" debe estar en segundos.
Asegurate de realizar todos los pasos posibles en el robot de cocina, como por ejemplo: batir huevos, picar o freir cebolla, mezclar, derretir chocolate etc.
`
	};

	const messages = [systemQuery];
	for (const idRecipe of exampleIdRecipes) {
		const { /*texto: exampleText,*/ json: exampleJson, porciones } = await getRecipeInfo(idRecipe);
		if (convertir) {
			const fakeUserQuery = {
				role: "user",
				content: `
Convierte la siguiente receta para preparar en iChef, separando los pasos en varias operaciones a realizar en iChef.
${exampleJson.titulo}
`
			};
			messages.push(fakeUserQuery);
		} else {
			const fakeUserQuery = {
				role: "user",
				content: `
Crea una receta para preparar en iChef.
${exampleJson.titulo ? `Título: ${exampleJson.titulo}` : ""}
${porciones > 0 ? `Para ${porciones} porciones.` : ""}
`
			};
			messages.push(fakeUserQuery);
		}

		const assistantQuery = {
			role: "assistant",
			content: JSON5.stringify(exampleJson)
		};
		messages.push(assistantQuery);
	}
	messages.push(userQuery);

	let data = testData;
	if (Object.keys(data).length === 0) {
		const queryResult = await QueryAI(messages);
		try {
			const startPos = queryResult.indexOf("{");
			const endPos = queryResult.lastIndexOf("}");
			if (startPos !== -1 && endPos !== -1 && endPos > startPos) {
				const extractedJson = queryResult.substring(startPos, endPos + 1);
				data = JSON5.parse(extractedJson);
			}
		} catch (error) {
			console.log("ERROR: ", error.message, queryResult);
			return {};
		}
	}
	console.log(data);
	return await parseRecipeData(data, insumos);
}

async function parseRecipeData(data, insumos) {
	const parseOperacion = operacion => {
		const operaciones = {
			amasar: 3,
			cocinar: 5,
			horno: 1,
			manual: 1,
			pesar: 6,
			precalentar: 12,
			procesar: 8,
			subtitulo: 9,
			vaporera: 2,
			varoma: 2
		};
		return operaciones[operacion && operacion.toLowerCase()] || 1;
	};

	const parseDireccion = direccion => {
		const direcciones = {
			reversa: 1,
			Avance: 2
		};
		return direcciones[direccion && direccion.toLowerCase()] || 2;
	};

	async function convertUnits(ingrediente) {
		const idInsumo = await getIdInsumoSearch(ingrediente.insumo || ingrediente.descripcion, insumos);
		let result = {
			cantidad: ingrediente?.cantidad > 0 ? Math.round(ingrediente.cantidad) : 5,
			nombreUnidad: ingrediente.unidad || "gramos",
			descripcion: ingrediente.descripcion || ingrediente.insumo,
			cantidadOC: ingrediente?.cantidad || 0,
			unidadOC: ingrediente.unidad || "gramos",
			idInsumo: 0,
			idUnidad: 1,
			marca: 2
		};

		if (idInsumo) {
			const insumo = insumos.find(i => i.idInsumo === idInsumo);
			if (!insumo) return result;

			result.idInsumo = idInsumo;
			result.nombreInsumo = insumo.nombreInsumo;
			if (!ingrediente.insumo) return result;
			result.marca = ingrediente.insumo.toLowerCase() !== insumo.nombreInsumo.toLowerCase() ? 1 : 0;

			let unidadIngr = ingrediente.unidad.toLowerCase();
			if (insumo.unidad.nombreUnidad.toLowerCase() === unidadIngr) {
				result.idUnidad = insumo.unidad.idUnidad;
				result.nombreUnidad = insumo.unidad.nombreUnidad;
			} else {
				for (const equivalencia of insumo.equivalencias) {
					const unidad = equivalencia.unidad1.toLowerCase().trim();
					if (unidadIngr.endsWith("s")) {
						unidadIngr = unidadIngr.substring(0, unidad.length - 1);
					}
					if (
						unidad === unidadIngr ||
						(unidad !== "cucharada" &&
							unidad !== "cucharadita" &&
							unidad !== "taza" &&
							unidadIngr !== "litro" &&
							unidadIngr !== "ml")
					) {
						result.cantidad = Math.round(ingrediente.cantidad * parseInt(equivalencia.cantidad2));
						result.nombreUnidad = equivalencia.unidad2;
						return result;
					}
				}
				const otrasEquivalencias = [
					{
						cantidad1: "1",
						unidad1: "kilo",
						cantidad2: "1000",
						unidad2: "gramos"
					},
					{
						cantidad1: "1",
						unidad1: "litro",
						cantidad2: "1000",
						unidad2: "mililitros"
					},
					{
						cantidad1: "1",
						unidad1: "mililitros",
						cantidad2: "1",
						unidad2: "gramos"
					},
					{
						cantidad1: "1",
						unidad1: "ml",
						cantidad2: "1",
						unidad2: "gramos"
					}
				];

				for (const equivalencia of otrasEquivalencias) {
					if (equivalencia.unidad1.toLowerCase() === ingrediente.unidad.toLowerCase()) {
						result.cantidad = Math.round(ingrediente.cantidad * parseInt(equivalencia.cantidad2));
						result.nombreUnidad = equivalencia.unidad2;
						return result;
					}
				}
			}
		}
		return result;
	}

	const parseIngredientes = ingredientes => {
		const procesed =
			ingredientes &&
			ingredientes.map(async ingrediente => {
				return await convertUnits(ingrediente);
			});
		return Promise.all(procesed);
	};

	const canHaveDescription = ({ idOperacion }) => {
		return idOperacion === 1 || idOperacion === 6 || idOperacion === 9;
	};

	const parsePasos = (pasos, titulo) => {
		const procesed =
			pasos &&
			pasos.map(paso => {
				return {
					cantidad: paso.cantidad,
					tiempo: paso.tiempo,
					temperatura: paso.temperatura,
					descripcion: paso.descripcion,
					nivelVelocidad: paso.velocidad,

					idOperacion: parseOperacion(paso.operacion),
					idModoVelocidad: parseDireccion(paso.direccion)
				};
			});
		if (!procesed) return [];
		// Si un paso no puede tener una descripción, se agrega en un nuevo paso manual antes
		const corrected = procesed.reduce((acc, paso) => {
			if (canHaveDescription(paso)) {
				acc.push(paso);
			} else {
				if (paso.descripcion) {
					const manualPaso = {
						idOperacion: 1,
						descripcion: paso.descripcion
					};
					acc.push(manualPaso);
				}
				const currentPaso = {
					...paso,
					descripcion: ""
				};
				acc.push(currentPaso);
			}
			return acc;
		}, []);

		const lastStep = {
			idOperacion: 1,
			descripcion: `Disfrutá de tu ${titulo} preparada en iChef.`
		};
		corrected.push(lastStep);

		corrected.map((paso, index) => {
			paso.numeroPaso = index + 1;
			return paso;
		});
		return corrected;
	};

	const correctedData = {
		titulo: data.titulo,
		ingredientes: await parseIngredientes(data.ingredientes),
		pasos: parsePasos(data.pasos, data.titulo)
	};
	return correctedData;
}

export { createRecipe, convertRecipe, modifyRecipe, generateRecipeJSON, getRecipeInfo };
