import { useState } from 'react'
import { ReactComponent as IcoUsuario } from '../../images/svg/ico-usuario.svg'
import { useAuthProvider } from '../../providers/auth'
import { FORMTYPE, STATUS_SERVER } from '../../providers/utils/functions'

function ChangePasswordForm({ setUserFormType, inputToken, setInputToken }) {
	//Proveedor de autenticación
	const authProvider = useAuthProvider()

	const [newPassword, setNewPassword] = useState('')
	const [confirmNewPassword, setConfirmNewPassword] = useState('')

	const [serverMsg, setServerMsg] = useState({ status: null, msg: '', show: false })

	const handleChangeOldPassword = async (e) => {
		e.preventDefault()

		if (newPassword === confirmNewPassword) {
			setServerMsg({ status: null, msg: '', show: false })
			authProvider
				.changeOldPassword(inputToken, newPassword)
				.then(async (response) => {
					const { status } = response || { status: null }

					if (status === 200) {
						setServerMsg({
							status: STATUS_SERVER.SUCCESS,
							msg: '¡Contraseña actualizada con éxito!.',
							show: true,
						})

						setNewPassword('')
						setConfirmNewPassword('')
						setInputToken('')
					}
				})
				.catch((error) => {
					// console.log('error', error)
					setServerMsg({ status: STATUS_SERVER.ERROR, msg: error, show: true })
				})
		} else {
			setServerMsg({ status: STATUS_SERVER.ERROR, msg: 'Las contraseñas no coinciden', show: true })
		}
	}

	return (
		<form action="" className="transition form-ichef">
			<header className="text-center">
				<h6>
					<IcoUsuario />
					{'Nueva contraseña'}
				</h6>
			</header>

			<div>
				<input
					type="password"
					autoComplete="new-password"
					name="new-password"
					placeholder="Nueva contraseña"
					onChange={(e) => setNewPassword(e.target.value)}
					value={newPassword}
					required
				/>
				<input
					type="password"
					autoComplete="new-password"
					name="confirm-new-password"
					placeholder="Repita la contraseña"
					onChange={(e) => setConfirmNewPassword(e.target.value)}
					value={confirmNewPassword}
					required
				/>
			</div>
			<button className="transition" name="submit" onClick={(e) => handleChangeOldPassword(e)}>
				Confirmar
			</button>
			<footer>
				<a
					href="/#"
					onClick={() => {
						setUserFormType(FORMTYPE.LOGIN)
						setServerMsg({ status: null, msg: '', show: false })
					}}
				>
					Iniciar sesión
				</a>
			</footer>

			{serverMsg && serverMsg.show ? (
				<div
					className={`msj ${
						serverMsg.status === STATUS_SERVER.ERROR
							? 'error'
							: serverMsg.status === STATUS_SERVER.SUCCESS
							? 'ok'
							: ''
					}`}
				>
					{serverMsg.msg}
				</div>
			) : (
				<></>
			)}
		</form>
	)
}

export default ChangePasswordForm
