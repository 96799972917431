import axios from 'axios'
import decodeJwt from 'jwt-decode'

export class AuthProvider {
	constructor(entrypoint, auths, store) {
		this.entrypoint = entrypoint
		this.auths = auths
		this.store = store
		this.isEnabledLocalStore = store ? true : false
		this.isEnabledRouteTracking = false

		this.userEmptyData = {
			nombre: null,
			usuario: null,
			jwt: null,
		}
	}

	setToken(token) {
		const decodedToken = decodeJwt(token)
		const { name, idUser, expInMillis } = decodedToken || {
			expInMillis: null,
		}
		if (expInMillis && expInMillis > Date.now()) {
			if (this.isEnabledLocalStore) {
				this.store.updateUserData({
					usuario: "invitado", // TODO: use GET api/configuracion/usuario
					idUsuario: idUser,
					nombre: name,
					jwt: token
				})
			}
			return true
		} else {
			return false
		}
	}

	login(username, password) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: `${this.entrypoint}/api/users/authenticate`,
				data: {
					username: username,
					password: password,
				},
			})
				.then((response) => {
					const { status, data: userAuthenticated } = response || { status: null, data: null }
					const { error: dataError } = userAuthenticated || { error: null }

					// const decodedToken = decodeJwt(token)
					// const { roles } = decodedToken

					if (status === 200 && userAuthenticated && !dataError) {
						if (this.isEnabledLocalStore) {
							this.store.updateUserData(userAuthenticated)
							this.store.updateFavorites([])
						}

						return resolve(userAuthenticated)
					} else {
						return reject('Verifique los datos de acceso.')
					}
				})
				.catch((error) => {
					reject(
						error && error.response && error.response.data && error.response.data.message
							? error.response.data.message
							: 'Error del servidor. Vuelva a intentarlo mas tarde.'
					)
				})
		})
	}

	reset(username = null, email = null) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: `${this.entrypoint}/api/users/reset-password`,
				data: {
					...(username && { username: username }),
					...(email && { email: email }),
				},
			})
				.then((response) => {
					return resolve(response)
				})
				.catch((error) => {
					// console.log('error: ', error)
					reject(
						error && error.response && error.response.data && error.response.data.message
							? error.response.data.message
							: 'Error del servidor. Vuelva a intentarlo mas tarde.'
					)
				})
		})
	}

	verifyToken(token) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: `${this.entrypoint}/api/users/verify-token`,
				data: {
					token: token,
				},
			})
				.then((response) => {
					return resolve(response)
				})
				.catch((error) => {
					reject(
						error && error.response && error.response.data && error.response.data.message
							? error.response.data.message
							: 'Error del servidor. Vuelva a intentarlo mas tarde.'
					)
				})
		})
	}

	changeOldPassword(token, newPassword) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: `${this.entrypoint}/api/users/change-old-password`,
				data: {
					token: token,
					newPassword: newPassword,
				},
			})
				.then((response) => {
					return resolve(response)
				})
				.catch((error) => {
					reject(
						error && error.response && error.response.data && error.response.data.message
							? error.response.data.message
							: 'Error del servidor. Vuelva a intentarlo mas tarde.'
					)
				})
		})
	}

	signUp({ name, surname, cellphone, email, contactId }) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: `${this.entrypoint}/api/users/signup`,
				data: {
					name: name,
					surname: surname,
					cellphone: cellphone,
					email: email,
					contactId: contactId,
				},
			}).then((response) => {
					return resolve(response)
				}).catch((error) => {
					reject(
						error && error.response && error.response.data && error.response.data.message
							? error.response.data.message
							: 'Error del servidor. Vuelva a intentarlo mas tarde.'
					)
				})
		})
	}

	// called when the user clicks on the logout button
	logout() {
		this.store.logout()
	}

	checkAuth() {
		this.auths = this.store.getAuths()
		var { userData } = this.auths || { userData: null }
		var { jwt: jwtToken } = userData || { jwt: null }

		if (jwtToken) {
			jwtToken = jwtToken.replace('Bearer')
			jwtToken = jwtToken.trim()

			const decodedToken = decodeJwt(jwtToken)
			const { expInMillis } = decodedToken || {
				expInMillis: null,
			}

			return expInMillis && expInMillis > Date.now() ? true : false
		} else {
			return false
		}
	}

	getJwtExpiration() {
		this.auths = this.store.getAuths()
		var { userData } = this.auths || { userData: null }
		var { jwt: jwtToken } = userData || { jwt: null }

		if (jwtToken) {
			jwtToken = jwtToken.replace('Bearer')
			jwtToken = jwtToken.trim()

			const decodedToken = decodeJwt(jwtToken)
			const { expInMillis } = decodedToken || {
				expInMillis: null,
			}

			return expInMillis
		} else {
			return null
		}
	}

	getIdentity() {
		this.auths = this.store.getAuths()
		return this.auths.userData ? this.auths.userData : this.userEmptyData
	}

	updateIdentity(data) {
		if (this.isEnabledLocalStore) {
			this.store.updateUserData(data)
		}
	}

	getFolders() {
		this.auths = this.store.getAuths()
		if (this.auths.folders) {
			return this.auths.folders.list ? this.auths.folders.list : []
		} else {
			this.auths.folders = { list: [], updated: 0 }
			return []
		}
	}

	getFolderUpdated() {
		this.auths = this.store.getAuths()
		if (this.auths.folders) {
			return this.auths.folders.updated ? this.auths.folders.updated : 0
		} else {
			this.auths.folders = { list: [], updated: 0 }
			return 0
		}
	}

	updateFolders(folders) {
		if (this.isEnabledLocalStore) {
			const updated = this.getFolderUpdated()
			this.store.updateFolders({"list": folders, "updated": updated + 1})
		}
	}

	getWatchLater() {
		this.auths = this.store.getAuths()
		return this.auths.watchLater || []
	}

	addToWatchLater(recipe) {
		if (this.isEnabledLocalStore) {
			const watchLater = this.getWatchLater()
			watchLater.push(recipe)
			this.store.updateWatchLater(watchLater)
		}
	}

	recipeIsInWatchLater(recipe) {
		if (!recipe) return false
		const watchLater = this.getWatchLater()
		return watchLater.findIndex((item) => item.idReceta === recipe.idReceta) > -1
	}

	removeFromWatchLater(recipe) {
		if (this.isEnabledLocalStore) {
			const watchLater = this.getWatchLater()
			const index = watchLater.findIndex((item) => item.idReceta === recipe.idReceta)
			if (index > -1) {
				watchLater.splice(index, 1)
			}
			this.store.updateWatchLater(watchLater)
		}
	}

	updateFavorites(favorites) {
		if (this.isEnabledLocalStore) {
			this.store.updateFavorites(favorites)
		}
	}

	getFavorites() {
		this.auths = this.store.getAuths()
		return this.auths.favorites ? this.auths.favorites : []
	}

	updateLikes(likes) {
		if (this.isEnabledLocalStore) {
			this.store.updateLikes(likes)
		}
	}

	getLikes() {
		this.auths = this.store.getAuths()
		return this.auths.likes ? this.auths.likes : []
	}

	deleteAuth(name) {
		if (this.isEnabledLocalStore) {
			this.auths = this.store.getAuths()
			delete this.auths[name]
			this.store.updateFilters({ ...this.auths })
		}
	}

	enableLocalStore(enable = true) {
		this.isEnabledLocalStore = enable
	}

	getAuth(name) {
		this.auths = this.store.getAuths()
		return typeof this.auths[name] !== 'undefined' ? this.auths[name] : null
	}

	getAuths() {
		return this.store.getAuths()
	}
}
